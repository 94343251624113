import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { EventsService } from 'src/app/service/events.service';

@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

    events: any;

    current: any;

    error: any;

    constructor(private readonly eventsService: EventsService) { }

    ngOnInit(): void {
        this.loadItems();
    }

    private loadItems() {
        this.eventsService.getAll().then((results) => this.events = results);
        this.current = undefined;
        this.error = undefined;
    }

    public edit(newsItem) {
        this.current = newsItem;
        this.current['date'] = new Date(this.current['date']);
    }

    private save() {
        this.eventsService.save(this.current)
            .then((results) => this.loadItems())
            .catch((error) => this.error = error);
    }

    private add() {
        this.current = {};
        this.current.date = new Date();
    }

    public cancel() {
        this.loadItems();
    }

}
