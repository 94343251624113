<div class="col-12 py-md-3" *ngIf="current">
    <div class="card">
        <div class="card-header">
            Deckrüde erfassen / bearbeiten
        </div>
        <div class="card-body">
            <form class="needs-validation" [class.was-validated]="_shsb.touched" novalidate>
                <div class="form-row">
                    <div class="col-md-1 mb-3">
                        <label for="shsb">SHSB</label>
                        <div class="input-group">
                            <input #_shsb="ngModel" type="text" class="form-control" id="shsb" name="shsb" required
                                maxlength="6" [(ngModel)]="current.shsb">
                        </div>
                        <div class="invalid-feedback">
                            Bitte valide SHSB Nummer eingeben.
                        </div>
                    </div>
                    <div class="col-md-5 mb-3">
                        <label for="name">Name des Hundes</label>
                        <div class="input-group">
                            <input #_name="ngModel" type="text" class="form-control" id="name" name="name" required
                                maxlength="250" [(ngModel)]="current.name">
                        </div>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label for="nickname">Rufname des Hundes</label>
                        <div class="input-group">
                            <input #_nickname="ngModel" type="text" class="form-control" id="nickname" name="nickname"
                                maxlength="250" [(ngModel)]="current.nickname">
                        </div>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label for="title">Titel</label>
                        <div class="input-group">
                            <select #_title="ngModel" type="text" class="form-control custom-select" id="title"
                                name="title" [(ngModel)]="current.title">
                                <option *ngFor="let title of titles">{{title}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label for="birthdate">Geburtsdatum</label>
                        <div class="input-group">
                            <input class="form-control" type="text" id="birthdate" name="birthdate"
                                [(ngModel)]="current.birthdate" container="body" ngbDatepicker #_birthdate="ngModel"
                                #d1="ngbDatepicker" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()"
                                    type="button"></button>
                            </div>
                        </div>
                        <div class="invalid-feedback">
                            Ungültiges Datum
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6 no-gutters">
                        <div class="col-12 mb-3">
                            <label for="ownerName">Besitzer Name</label>
                            <div class="input-group">
                                <input #_ownerName="ngModel" type="text" class="form-control" id="ownerName"
                                    name="ownerName" maxlength="250" [(ngModel)]="current.ownerName">
                            </div>
                        </div>
                        <div class="col-12 mb-3">
                            <label for="town">PLZ Wohnort</label>
                            <div class="input-group">
                                <input #_town="ngModel" type="text" class="form-control" id="town" name="town"
                                    maxlength="250" [(ngModel)]="current.town">
                            </div>
                        </div>
                        <div class="col-12 mb-3">
                            <label for="phone">Telefonnummern (mit + getrennt wenn mehrere)</label>
                            <div class="input-group">
                                <input #_phone="ngModel" type="text" class="form-control" id="phone" name="phone"
                                    maxlength="250" [(ngModel)]="current.phone" placeholder="000 000 00 00">
                            </div>
                        </div>
                    </div>
                    <div class="col-6 no-gutters">
                        <div class="col-md-12 mb-3">
                            <label for="email">e-Mail Adresse</label>
                            <div class="input-group">
                                <input #_email="ngModel" type="text" class="form-control" id="email" name="email"
                                    maxlength="250" [(ngModel)]="current.email">
                            </div>
                        </div>
                        <div class="col-12 mb-3">
                            <label for="website">Webseite</label>
                            <div class="input-group">
                                <input #_website="ngModel" type="text" class="form-control" id="website" name="website"
                                    [(ngModel)]="current.website" pattern="https?://.+" placeholder="https://">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <label for="father">Vater</label>
                        <div class="input-group">
                            <input #_father="ngModel" type="text" class="form-control" id="father" name="father"
                                maxlength="250" [(ngModel)]="current.father">
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="mother">Mutter</label>
                        <div class="input-group">
                            <input #_mother="ngModel" type="text" class="form-control" id="mother" name="mother"
                                maxlength="250" [(ngModel)]="current.mother">
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-2 mb-3">
                        <label for="cea">CEA</label>
                        <div class="input-group">
                            <select #_cea="ngModel" type="text" class="form-control custom-select" id="cea" name="cea"
                                maxlength="250" [(ngModel)]="current.cea">
                                <option *ngFor="let value of testList1">{{value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label for="pra">PRA</label>
                        <div class="input-group">
                            <select #_pra="ngModel" type="text" class="form-control custom-select" id="pra" name="pra"
                                maxlength="250" [(ngModel)]="current.pra">
                                <option *ngFor="let value of testList1">{{value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label for="kat">KAT</label>
                        <div class="input-group">
                            <select #_kat="ngModel" type="text" class="form-control custom-select" id="kat" name="kat"
                                maxlength="250" [(ngModel)]="current.kat">
                                <option *ngFor="let value of testList1">{{value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label for="mdr1">MDR1</label>
                        <div class="input-group">
                            <select #_mdr1="ngModel" type="text" class="form-control custom-select" id="mdr1"
                                name="mdr1" maxlength="250" [(ngModel)]="current.mdr1">
                                <option *ngFor="let value of mdr1List">{{value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label for="dm">DM</label>
                        <div class="input-group">
                            <select #_dm="ngModel" type="text" class="form-control custom-select" id="dm" name="dm"
                                maxlength="250" [(ngModel)]="current.dm">
                                <option *ngFor="let value of testList1">{{value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label for="vwd3">VWD3</label>
                        <div class="input-group">
                            <select #_vwd3="ngModel" type="text" class="form-control custom-select" id="vwd3"
                                name="vwd3" maxlength="250" [(ngModel)]="current.vwd3">
                                <option *ngFor="let value of testList1">{{value}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-2 mb-3">
                        <label for="ceaDate">CEA Test-Datum</label>
                        <div class="input-group">
                            <input class="form-control" type="text" id="ceaDate" name="ceaDate"
                                [(ngModel)]="current.ceaDate" container="body" ngbDatepicker #_ceaDate="ngModel"
                                #dceaDate="ngbDatepicker" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="dceaDate.toggle()"
                                    type="button"></button>
                            </div>
                        </div>
                        <div class="invalid-feedback">
                            Ungültiges Datum
                        </div>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label for="praDate">PRA Test-Datum</label>
                        <div class="input-group">
                            <input class="form-control" type="text" id="praDate" name="praDate"
                                [(ngModel)]="current.praDate" container="body" ngbDatepicker #_praDate="ngModel"
                                #dpraDate="ngbDatepicker" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="dpraDate.toggle()"
                                    type="button"></button>
                            </div>
                        </div>
                        <div class="invalid-feedback">
                            Ungültiges Datum
                        </div>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label for="katDate">KAT Test-Datum</label>
                        <div class="input-group">
                            <input class="form-control" type="text" id="katDate" name="katDate"
                                [(ngModel)]="current.katDate" container="body" ngbDatepicker #_katDate="ngModel"
                                #dkatDate="ngbDatepicker" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="dkatDate.toggle()"
                                    type="button"></button>
                            </div>
                        </div>
                        <div class="invalid-feedback">
                            Ungültiges Datum
                        </div>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label for="mdr1Date">MDR1 Test-Datum</label>
                        <div class="input-group">
                            <input class="form-control" type="text" id="mdr1Date" name="mdr1Date"
                                [(ngModel)]="current.mdr1Date" container="body" ngbDatepicker #_mdr1Date="ngModel"
                                #dmdr1Date="ngbDatepicker" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="dmdr1Date.toggle()"
                                    type="button"></button>
                            </div>
                        </div>
                        <div class="invalid-feedback">
                            Ungültiges Datum
                        </div>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label for="dmDate">DM Test-Datum</label>
                        <div class="input-group">
                            <input class="form-control" type="text" id="dmDate" name="dmDate"
                                [(ngModel)]="current.dmDate" container="body" ngbDatepicker #_dmDate="ngModel"
                                #ddmDate="ngbDatepicker" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="ddmDate.toggle()"
                                    type="button"></button>
                            </div>
                        </div>
                        <div class="invalid-feedback">
                            Ungültiges Datum
                        </div>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label for="vwd3Date">VWD3 Test-Datum</label>
                        <div class="input-group">
                            <input class="form-control" type="text" id="vwd3Date" name="vwd3Date"
                                [(ngModel)]="current.vwd3Date" container="body" ngbDatepicker #_vwd3Date="ngModel"
                                #dvwd3Date="ngbDatepicker" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="dvwd3Date.toggle()"
                                    type="button"></button>
                            </div>
                        </div>
                        <div class="invalid-feedback">
                            Ungültiges Datum
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <label for="additional">Zusätzliche Tests (Deutsch)</label>
                        <div class="input-group">
                            <input #_additional="ngModel" type="text" class="form-control" id="additional"
                                name="additional" maxlength="250" [(ngModel)]="current.additional">
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="additional_fr">Zusätzliche Tests (Französisch)</label>
                        <div class="input-group">
                            <input #_additional_fr="ngModel" type="text" class="form-control" id="additional_fr"
                                name="additional_fr" maxlength="250" [(ngModel)]="current.additional_fr">
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-2 mb-3">
                        <label for="hd">HD</label>
                        <div class="input-group">
                            <select #_hd="ngModel" type="text" class="form-control custom-select" id="hd" name="hd"
                                maxlength="250" [(ngModel)]="current.hd">
                                <option *ngFor="let value of hdList">{{value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-5 mb-3">
                        <label for="gelenke">Gelenke (Deutsch)</label>
                        <div class="input-group">
                            <input #_gelenke="ngModel" type="text" class="form-control" id="gelenke" name="gelenke"
                                maxlength="250" [(ngModel)]="current.gelenke">
                        </div>
                    </div>
                    <div class="col-md-5 mb-3">
                        <label for="gelenke_fr">Gelenke (Französisch)</label>
                        <div class="input-group">
                            <input #_gelenke_fr="ngModel" type="text" class="form-control" id="gelenke_fr"
                                name="gelenke_fr" maxlength="250" [(ngModel)]="current.gelenke_fr">
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-3 mb-3">
                        <label for="color">Farbe</label>
                        <div class="input-group">
                            <select #_color="ngModel" type="text" class="form-control custom-select" id="color"
                                name="color" [(ngModel)]="current.color">
                                <option *ngFor="let value of colorList">{{value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <label for="height">Grösse</label>
                        <div class="input-group">
                            <input #_height="ngModel" type="text" class="form-control" id="height" name="height"
                                [(ngModel)]="current.height">
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <label for="formwert">Formwert</label>
                        <div class="input-group">
                            <select #_formwert="ngModel" type="text" class="form-control custom-select" id="formwert"
                                name="formwert" [(ngModel)]="current.formwert">
                                <option *ngFor="let value of formwertList">{{value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <label for="surveydate">Ankörungsdatum</label>
                        <div class="input-group">
                            <input class="form-control" type="text" id="surveydate" name="surveydate"
                                [(ngModel)]="current.surveydate" container="body" ngbDatepicker #_surveydate="ngModel"
                                #dsurveydate="ngbDatepicker" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="dsurveydate.toggle()"
                                    type="button"></button>
                            </div>
                        </div>
                        <div class="invalid-feedback">
                            Ungültiges Datum
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <label for="gebiss">Gebiss (Deutsch)</label>
                        <div class="input-group">
                            <input #_gebiss="ngModel" type="text" class="form-control" id="gebiss" name="gebiss"
                                maxlength="250" [(ngModel)]="current.gebiss">
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="gebiss_fr">Gebiss (Französisch)</label>
                        <div class="input-group">
                            <input #_gebiss_fr="ngModel" type="text" class="form-control" id="gebiss_fr"
                                name="gebiss_fr" maxlength="250" [(ngModel)]="current.gebiss_fr">
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <label for="zuchtauflagen">Zuchtauflagen (Deutsch)</label>
                        <div class="input-group">
                            <input #_zuchtauflagen="ngModel" type="text" class="form-control" id="zuchtauflagen"
                                name="zuchtauflagen" maxlength="250" [(ngModel)]="current.zuchtauflagen">
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="zuchtauflagen_fr">Zuchtauflagen (Französisch)</label>
                        <div class="input-group">
                            <input #_zuchtauflagen_fr="ngModel" type="text" class="form-control" id="zuchtauflagen_fr"
                                name="zuchtauflagen_fr" maxlength="250" [(ngModel)]="current.zuchtauflagen_fr">
                        </div>
                    </div>
                </div>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-3">{{error.statusText}}</div>
                <div class="text-right">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary" [disabled]="_shsb.invalid"
                            (click)="save()">Speichern</button>
                        <button type="button" class="btn btn-link" (click)="cancel()">Abbrechen</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="col-12 py-md-3" *ngIf="!current">
    <div class="card">
        <div class="card-header">
            Übersicht
        </div>
        <div class="card-body">
            <table class="table table-sm table-striped table-hover">
                <thead>
                    <th style="width: 8rem">SHSB</th>
                    <th>Name</th>
                    <th>Besitzer</th>
                    <th style="width: 14rem">Aktionen</th>
                </thead>
                <tbody>
                    <tr class="table-row" *ngFor="let item of items">
                        <td>{{item.shsb}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.ownerName}}</td>
                        <td>
                            <div class="btn-group">
                                <button type="button" class="btn btn-link" [disabled]="true"
                                    (click)="edit(item)">Editieren</button>
                                <button type="button" class="btn btn-link" [disabled]="true">Löschen</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="text-right">
                <div class="btn-group">
                    <button type="button" class="btn btn-primary" (click)="add()">Hinzufügen</button>
                </div>
            </div>
        </div>
    </div>