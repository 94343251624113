import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { PwStrengthService } from '../service/pw-strength.service';

@Directive({
    selector: '[appPwStrength]',
    providers: [{ provide: NG_VALIDATORS, useExisting: PwStrengthDirective, multi: true }]
})
export class PwStrengthDirective implements Validator {

    @Input()
    appPwStrength: string | number;

    constructor(private readonly pwStrengthService: PwStrengthService) {
    }

    validate(control: AbstractControl): { [key: string]: any } | null {
        const password2 = control.parent.get('password2');
        if (control.value) {
            const rank = this.pwStrengthService.measureStrength(control.value).rank;
            password2.updateValueAndValidity();
            if (rank >= 2) {
                return null;
            }
            return { 'minstrength': { 'requiredStrength': this.appPwStrength, 'actualStrength': rank } };
        }
        return { 'minstrength': { 'requiredStrength': this.appPwStrength, 'actualStrength': 0 } };
    }
}
