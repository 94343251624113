import { Component, OnInit } from '@angular/core';
import { UserService } from '../service/user.service';

@Component({
    selector: 'sssc-menu',
    templateUrl: './sssc-menu.component.html',
    styleUrls: ['./sssc-menu.component.scss']
})
export class SsscMenuComponent implements OnInit {

    constructor(public readonly userService: UserService) { }

    ngOnInit(): void {
    }

    hasRole(role: string): boolean {
        return this.userService.user?.rights?.indexOf(role) >= 0;
    }
}
