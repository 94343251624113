import { Component, OnInit } from '@angular/core';
import { ZuchthuendinnenService } from 'src/app/service/zuchthuendinnen.service';
import { UserService } from './../../service/user.service';
import * as XLSX from 'xlsx';

@Component({
	selector: 'app-zuchthuendinnen',
	templateUrl: './zuchthuendinnen.component.html',
	styleUrls: ['./zuchthuendinnen.component.scss']
})
export class ZuchthuendinnenComponent implements OnInit {

	items: any;

	current: any;

	error: any;

	titles: string[] = [null];

	testList1 = [undefined, 'gen. frei', 'gen. carrier', 'frei', 'frei, gen. carrier'];

	testListEcvo = [undefined, 'frei von allen erblichen Augenkrankheiten'];

	colorList = ['bi-blue', 'bluemerle', 'bluemerle, bi-fakt.', 'sable', 'schwarz-weiss', 'tricolor'];

	formwertList = [undefined, 'vorzüglich', 'sehr gut'];

	mdr1List: string[] = [undefined, '+/+', '+/-', '-/-'];
	hdList: string[] = [undefined, '0/0', '0', '1/1', '1', 'A/A', 'A', 'B/B', 'B'];

	edList: string[] = [undefined, '0/0', '0', '1/1', '1'];
	ocdList: string[] = [undefined, 'frei/frei', 'frei'];
	lsugwList: string[] = [undefined, 'Typ 0', 'Typ 1', 'Typ 2', 'Typ 3', 'Typ 4'];

	constructor(private readonly zuechthuendinnen: ZuchthuendinnenService,
		public readonly userService: UserService) {
	}

	ngOnInit(): void {
		this.loadItems();
	}

	get mayEdit(): boolean {
		return this.userService.user?.rights?.indexOf('ZUCHT_WRITE') >= 0;
	}

	private loadItems() {
		this.zuechthuendinnen.getAll().then((results) => this.loadResults(results));
		this.current = undefined;
		this.error = undefined;
	}

	private loadResults(results) {
		this.items = results;

		this.titles = this.collectUnique(results, [null], 'title');
		this.testList1 = this.collectUnique(results, this.testList1, 'cea');
		this.testList1 = this.collectUnique(results, this.testList1, 'pra');
		this.testList1 = this.collectUnique(results, this.testList1, 'kat');
		this.testList1 = this.collectUnique(results, this.testList1, 'dm');
		this.testList1 = this.collectUnique(results, this.testList1, 'vwd3');

		this.testListEcvo = this.collectUnique(results, this.testListEcvo, 'ecvo');

		this.mdr1List = this.collectUnique(results, this.mdr1List, 'mdr1');
		this.hdList = this.collectUnique(results, this.hdList, 'hd');

		this.edList = this.collectUnique(results, this.edList, 'ed');
		this.ocdList = this.collectUnique(results, this.ocdList, 'ocd');
		this.lsugwList = this.collectUnique(results, this.lsugwList, 'lsugw');

		this.colorList = this.collectUnique(results, this.colorList, 'color');
		this.formwertList = this.collectUnique(results, this.formwertList, 'formwert');
	}

	private collectUnique(results: any, list: string[], value: string) {
		results.forEach(element => {
			if (element[value] && list.indexOf(element[value]) < 0) {
				list.push(element[value]);
			}
		});
		return list;
	}

	public edit(newsItem) {
		this.current = newsItem;
		if (this.current['birthdate']) {
			this.current['birthdate'] = new Date(this.current['birthdate']);
		}
		if (this.current['surveydate']) {
			this.current['surveydate'] = new Date(this.current['surveydate']);
		}

		if (this.current['ceaDate']) {
			this.current['ceaDate'] = new Date(this.current['ceaDate']);
		}
		if (this.current['praDate']) {
			this.current['praDate'] = new Date(this.current['praDate']);
		}
		if (this.current['katDate']) {
			this.current['katDate'] = new Date(this.current['katDate']);
		}
		if (this.current['dmDate']) {
			this.current['dmDate'] = new Date(this.current['dmDate']);
		}
		if (this.current['vwd3Date']) {
			this.current['vwd3Date'] = new Date(this.current['vwd3Date']);
		}
		if (this.current['mdr1Date']) {
			this.current['mdr1Date'] = new Date(this.current['mdr1Date']);
		}
	}

	private save() {
		this.zuechthuendinnen.save(this.current)
			.then((results) => this.loadItems())
			.catch((error) => this.error = error);
	}

	private delete(dog) {
		this.zuechthuendinnen.delete(dog)
			.then((results) => this.loadItems())
			.catch((error) => this.error = error);
	}

	private add() {
		this.current = {};
		this.current.date = new Date();
	}

	public cancel() {
		this.loadItems();
	}

	public exportExcel() {
		const data = [];

		const headers = [];

		for (const item of Object.keys(this.items[0])) {
			if (isNaN(Number(item)) && item !== 'version' && item !== 'changedBy') {
				headers.push(item);
			}
		}
		data.push(headers);

		for (const item of this.items) {
			const itemData = []
			for (const header of headers) {
				itemData.push(item[header]);
			}
			data.push(itemData);
		}

		/* generate worksheet */
		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

		/* save to file */
		XLSX.writeFile(wb, 'Zuchthündinnen.ods');
	}
}
