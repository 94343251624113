<nav class="bg-light sidebar">
    <div class="sidebar-sticky">
        <div *ngIf="hasRole('NEWS_READ') || hasRole('EVENTS_READ')">
            <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>Informationen</span>
            </h6>
            <ul class="nav flex-column">
                <li *ngIf="hasRole('NEWS_READ')"><a class="nav-link" routerLink="/news"
                        routerLinkActive="active">Aktuelles</a></li>
                <li *ngIf="hasRole('EVENTS_READ')"><a class="nav-link" routerLink="/events"
                        routerLinkActive="active">Agenda</a></li>
            </ul>
        </div>

        <div *ngIf="hasRole('ZUCHT_READ')">
            <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>Zucht</span>
            </h6>
            <ul class="nav flex-column">
                <li><a class="nav-link" routerLink="/deckrueden" routerLinkActive="active">Deckrüden</a></li>
                <li><a class="nav-link" routerLink="/zuchthuendinnen" routerLinkActive="active">Zuchthündinnen</a></li>
                <li><a class="nav-link" routerLink="/zuechter" routerLinkActive="active">Züchter</a></li>
                <!-- <li><a class="nav-link" href="#">Deckmeldungen</a></li> -->
                <!-- <li><a class="nav-link" href="#">Wurfmeldungen</a></li> -->
            </ul>
        </div>
    </div>
</nav>