import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { UserService } from './../../service/user.service'

@Component({
    selector: 'app-reset',
    templateUrl: './reset.component.html',
    styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

    email: string;
    passwd: string;
    passwd2: string;
    token: string;

    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';

    public barLabel: string = "Passwort Stärke:";

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService
    ) {
    }

    ngOnInit(): void {
    }

    validatePw($event) {
        console.log($event);
    }

    onSubmit() {
        this.submitted = true;

        if (this.email && this.token && this.passwd) {
            this.loading = true;
            this.userService.reset(this.email, this.token, this.passwd)
                .then(
                    data => {
                        this.router.navigate(['/login']);
                    },
                    error => {
                        this.error = error;
                        this.loading = false;
                    });
        }
    }
}
