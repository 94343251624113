import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbDateParserFormatter, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ResetComponent } from './login/reset/reset.component';
import { SsscMenuComponent } from './sssc-menu/sssc-menu.component';
import { PasswordStrengthBarComponent } from './components/password-strength-bar/password-strength-bar.component';
import { PwStrengthDirective } from './directive/pw-strength.directive';
import { PwMatchDirective } from './directive/pw-match.directive';
import { NewsComponent } from './page/news/news.component';
import { EventsComponent } from './page/events/events.component';

import '@angular/common/locales/global/de-CH';

import { NgbDateI18NParserFormatter } from './provider/ngb-date-i18-nparser-formatter.provider';
import { DeckruedenComponent } from './page/deckrueden/deckrueden.component';
import { ZuchthuendinnenComponent } from './page/zuchthuendinnen/zuchthuendinnen.component';
import { FormTestInputComponent } from './components/form-test-input/form-test-input.component';
import { ZuechterComponent } from './page/zuechter/zuechter.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ResetComponent,
        SsscMenuComponent,
        PasswordStrengthBarComponent,
        PwStrengthDirective,
        PwMatchDirective,
        NewsComponent,
        EventsComponent,
        DeckruedenComponent,
        ZuchthuendinnenComponent,
        FormTestInputComponent,
        ZuechterComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        AppRoutingModule
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'de-CH' },
        { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
        { provide: NgbDateParserFormatter, useClass: NgbDateI18NParserFormatter },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
