<div class="col-12 py-md-3" *ngIf="current">
	<div class="card">
		<div class="card-header">Züchter erfassen / bearbeiten</div>
		<div class="card-body">
			<form class="needs-validation"
				[class.was-validated]="_kennel.touched || _website.touched || _displayname.touched" novalidate>
				<div class="form-row">
					<div class="col-12 mb-3">
						<label for="kennel">Zuchtstätte</label>
						<div class="input-group">
							<input #_kennel="ngModel" type="text" class="form-control"
								required id="kennel" name="kennel" [(ngModel)]="current.kennel">
						</div>
					</div>
				</div>
				<div class="form-row">
					<div class="col-6 mb-3">
						<div class="form-row">
							<div class="col-12 mb-3">
								<label for="url">Name</label>
								<div class="input-group">
									<input #_displayname="ngModel" type="text" class="form-control" required
										id="displayname" name="displayname"
										[(ngModel)]="current.displayname">
								</div>
							</div>
							<div class="col-12 mb-3">
								<label for="url">Adresse</label>
								<div class="input-group">
									<input #_street="ngModel" type="text" class="form-control"
										id="street" name="street" [(ngModel)]="current.street">
								</div>
							</div>
							<div class="col-4 mb-3">
								<label for="url">PLZ</label>
								<div class="input-group">
									<input #_zip="ngModel" type="text" class="form-control" id="zip"
										name="zip" [(ngModel)]="current.zip">
								</div>
							</div>
							<div class="col-8 mb-3">
								<label for="url">Ort</label>
								<div class="input-group">
									<input #_town="ngModel" type="text" class="form-control"
										id="town" name="town" [(ngModel)]="current.town">
								</div>
							</div>
						</div>
					</div>
					<div class="col-6 mb-3">
						<div class="form-row">
							<div class="col-12 mb-3">
								<label for="url">Telefon</label>
								<div class="input-group">
									<input #_phone="ngModel" type="text" class="form-control"
										id="phone" name="phone" [(ngModel)]="current.phone">
								</div>
							</div>
							<div class="col-12 mb-3">
								<label for="url">e-Mail Adresse</label>
								<div class="input-group">
									<input #_email="ngModel" type="text" class="form-control"
										id="email" name="email" [(ngModel)]="current.email">
								</div>
							</div>
							<div class="col-12 mb-3">
								<label for="url">Webseite</label>
								<div class="input-group">
									<input #_website="ngModel" type="text" class="form-control"
										id="website" name="website" [(ngModel)]="current.website"
										pattern="https?://.+" placeholder="https://">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="error" class="alert alert-danger mt-3 mb-3">{{error.statusText}}</div>
				<div class="text-right">
					<div class="btn-group">
						<button type="button" class="btn btn-primary"
							[disabled]="_kennel.invalid || _website.invalid || _displayname.invalid"
							(click)="save()">Speichern</button>
						<button type="button" class="btn btn-link" (click)="cancel()">Abbrechen</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
<div class="col-12 py-md-3" *ngIf="!current">
	<div class="card">
		<div class="card-header">
			<div class="row">
				<div class="col-md-6">Übersicht</div>
				<div class="col-md-6 text-right">
					<button type="button" class="btn btn-sm btn-primary ml-1"
						[disabled]="!mayEdit" (click)="add()">Hinzufügen</button>
					<button type="button" class="btn btn-sm btn-primary ml-1"
						(click)="exportExcel()">Excel Export</button>
				</div>
			</div>
		</div>
		<div class="card-body">
			<table class="table table-sm table-striped table-hover">
				<thead>
					<th>Züchter</th>
					<th>Kontakt</th>
					<th>Zuchtstätte</th>
					<th style="width: 14rem">Aktionen</th>
				</thead>
				<tbody>
					<tr class="table-row" *ngFor="let item of zuechterList">
						<td>
							<div>{{item.displayname}}</div>
							<div>{{item.street}}</div>
							<div>{{item.zip}} {{item.town}}</div>
						</td>
						<td>
							<div *ngIf="item.phone">{{item.phone}}</div>
							<div *ngIf="item.email">{{item.email}}</div>
							<div *ngIf="item.website">{{item.website}}</div>
						</td>
						<td>{{item.kennel}}</td>
						<td>
							<div class="btn-group">
								<button type="button" class="btn btn-link" [disabled]="current || !mayEdit"
									(click)="edit(item)">Editieren</button>
								<button type="button" class="btn btn-link" [disabled]="current || !mayEdit"
									(click)="delete(item)">Löschen</button>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>