import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ZuechterService {
    private readonly options = {
        withCredentials: true
    };

    constructor(private http: HttpClient) { }

    public getAll(): Promise<any> {
        return this.http.get<string>('api/zucht/zuechter', this.options).toPromise();
    }

    public save(zuechter: any): Promise<any> {
        return this.http.put<string>('api/zucht/zuechter', zuechter, this.options).toPromise();
    }

    public delete(zuechter: any): Promise<any> {
        return this.http.delete<string>('api/zucht/zuechter/' + zuechter.id).toPromise();
    }

}
