import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { ResetComponent } from './login/reset/reset.component';
import { NewsComponent } from './page/news/news.component';
import { EventsComponent } from './page/events/events.component';
import { DeckruedenComponent } from './page/deckrueden/deckrueden.component';
import { ZuchthuendinnenComponent } from './page/zuchthuendinnen/zuchthuendinnen.component';
import { ZuechterComponent } from './page/zuechter/zuechter.component';

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'login/reset',
        component: ResetComponent
    },
    {
        path: 'news',
        component: NewsComponent
    },
    {
        path: 'events',
        component: EventsComponent
    },
    {
        path: 'deckrueden',
        component: DeckruedenComponent
    },
    {
        path: 'zuchthuendinnen',
        component: ZuchthuendinnenComponent
    },
    {
        path: 'zuechter',
        component: ZuechterComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
