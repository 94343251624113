<div class="col-12 py-md-3" *ngIf="current">
	<div class="card">
		<div class="card-header">Zuchthündin erfassen / bearbeiten</div>
		<div class="card-body">
			<form class="needs-validation" [class.was-validated]="_shsb.touched"
				novalidate>
				<div class="form-row">
					<div class="col-7">
						<div class="form-row">
							<div class="col-6">
								<div class="form-row">
									<div class="col-6 mb-3">
										<label for="shsb">SHSB</label>
										<div class="input-group">
											<input #_shsb="ngModel" type="text"
												class="form-control form-control-sm" id="shsb" name="shsb"
												required [readonly]="current.version" maxlength="6"
												[(ngModel)]="current.shsb">
										</div>
										<div class="invalid-feedback">Bitte valide SHSB Nummer
											eingeben.</div>
									</div>
									<div class="col-6 mb-3">
										<label for="birthdate">Geburtsdatum</label>
										<div class="input-group">
											<input class="form-control form-control-sm" type="text"
												id="birthdate" name="birthdate"
												[(ngModel)]="current.birthdate" container="body"
												ngbDatepicker #_birthdate="ngModel" #d1="ngbDatepicker" />
											<div class="input-group-append">
												<button class="btn btn-outline-secondary calendar"
													(click)="d1.toggle()" type="button"></button>
											</div>
										</div>
										<div class="invalid-feedback">Ungültiges Datum</div>
									</div>
									<div class="col-12 mb-3">
										<label for="name">Name des Hundes</label>
										<div class="input-group">
											<input #_name="ngModel" type="text"
												class="form-control form-control-sm" id="name" name="name"
												required maxlength="250" [(ngModel)]="current.name">
										</div>
									</div>
									<div class="col-12 mb-3">
										<label for="nickname">Rufname des Hundes</label>
										<div class="input-group">
											<input #_nickname="ngModel" type="text"
												class="form-control form-control-sm" id="nickname"
												name="nickname" maxlength="250"
												[(ngModel)]="current.nickname">
										</div>
									</div>
									<div class="col-6 mb-3">
										<label for="color">Farbe</label>
										<div class="input-group">
											<select #_color="ngModel" type="text"
												class="form-control form-control-sm custom-select custom-select-sm"
												id="color" name="color" [(ngModel)]="current.color">
												<option *ngFor="let value of colorList">{{value}}</option>
											</select>
										</div>
									</div>
									<div class="col-6 mb-3">
										<label for="height">Grösse</label>
										<div class="input-group">
											<input #_height="ngModel" type="text"
												class="form-control form-control-sm" id="height"
												name="height" [(ngModel)]="current.height">
										</div>
									</div>
									<div class="col-12 mb-3">
										<label for="title">Titel</label>
										<div class="input-group">
											<select #_title="ngModel" type="text"
												class="form-control form-control-sm custom-select custom-select-sm"
												id="title" name="title" [(ngModel)]="current.title">
												<option *ngFor="let title of titles">{{title}}</option>
											</select>
										</div>
									</div>
								</div>
							</div>
							<div class="col-6">
								<div class="form-row">
									<div class="col-12 mb-3">
										<label for="ownerName">Besitzer Name</label>
										<div class="input-group">
											<input #_ownerName="ngModel" type="text"
												class="form-control form-control-sm" id="ownerName"
												name="ownerName" maxlength="250"
												[(ngModel)]="current.ownerName">
										</div>
									</div>
									<div class="col-12 mb-3">
										<label for="town">PLZ Wohnort</label>
										<div class="input-group">
											<input #_town="ngModel" type="text"
												class="form-control form-control-sm" id="town" name="town"
												maxlength="250" [(ngModel)]="current.town">
										</div>
									</div>
									<div class="col-12 mb-3">
										<label for="phone">Telefonnummern <small>(mit
												+ getrennt wenn mehrere)</small></label>
										<div class="input-group">
											<input #_phone="ngModel" type="text"
												class="form-control form-control-sm" id="phone" name="phone"
												maxlength="250" [(ngModel)]="current.phone"
												placeholder="000 000 00 00">
										</div>
									</div>
									<div class="col-md-12 mb-3">
										<label for="email">e-Mail Adresse</label>
										<div class="input-group">
											<input #_email="ngModel" type="text"
												class="form-control form-control-sm" id="email" name="email"
												maxlength="250" [(ngModel)]="current.email">
										</div>
									</div>
									<div class="col-12 mb-3">
										<label for="website">Webseite</label>
										<div class="input-group">
											<input #_website="ngModel" type="text"
												class="form-control form-control-sm" id="website"
												name="website" [(ngModel)]="current.website"
												pattern="https?://.+" placeholder="https://">
										</div>
									</div>
								</div>
							</div>


							<div class="col-12 mb-3">
								<label for="father">Vater</label>
								<div class="input-group">
									<input #_father="ngModel" type="text"
										class="form-control form-control-sm" id="father" name="father"
										maxlength="250" [(ngModel)]="current.father">
								</div>
							</div>
							<div class="col-12 mb-3">
								<label for="mother">Mutter</label>
								<div class="input-group">
									<input #_mother="ngModel" type="text"
										class="form-control form-control-sm" id="mother" name="mother"
										maxlength="250" [(ngModel)]="current.mother">
								</div>
							</div>

							<div class="col-6 mb-3">
								<label for="formwert">Formwert</label>
								<div class="input-group">
									<select #_formwert="ngModel" type="text"
										class="form-control form-control-sm custom-select custom-select-sm"
										id="formwert" name="formwert" [(ngModel)]="current.formwert">
										<option *ngFor="let value of formwertList">{{value}}</option>
									</select>
								</div>
							</div>
							<div class="col-6 mb-3">
								<label for="surveydate">Ankörungsdatum</label>
								<div class="input-group">
									<input class="form-control form-control-sm" type="text"
										id="surveydate" name="surveydate"
										[(ngModel)]="current.surveydate" container="body"
										ngbDatepicker #_surveydate="ngModel"
										#dsurveydate="ngbDatepicker" />
									<div class="input-group-append">
										<button class="btn btn-outline-secondary calendar"
											(click)="dsurveydate.toggle()" type="button"></button>
									</div>
								</div>
								<div class="invalid-feedback">Ungültiges Datum</div>
							</div>
							<div class="col-12 mb-3">
								<label for="gebiss">Gebiss (Deutsch)</label>
								<div class="input-group">
									<input #_gebiss="ngModel" type="text"
										class="form-control form-control-sm" id="gebiss" name="gebiss"
										maxlength="250" [(ngModel)]="current.gebiss">
								</div>
							</div>
							<div class="col-12 mb-3">
								<label for="gebiss_fr">Gebiss (Französisch)</label>
								<div class="input-group">
									<input #_gebiss_fr="ngModel" type="text"
										class="form-control form-control-sm" id="gebiss_fr"
										name="gebiss_fr" maxlength="250"
										[(ngModel)]="current.gebiss_fr">
								</div>
							</div>
							<div class="col-12 mb-3">
								<label for="zuchtauflagen">Zuchtauflagen (Deutsch)</label>
								<div class="input-group">
									<input #_zuchtauflagen="ngModel" type="text"
										class="form-control form-control-sm" id="zuchtauflagen"
										name="zuchtauflagen" maxlength="250"
										[(ngModel)]="current.zuchtauflagen">
								</div>
							</div>
							<div class="col-12 mb-3">
								<label for="zuchtauflagen_fr">Zuchtauflagen
									(Französisch)</label>
								<div class="input-group">
									<input #_zuchtauflagen_fr="ngModel" type="text"
										class="form-control form-control-sm" id="zuchtauflagen_fr"
										name="zuchtauflagen_fr" maxlength="250"
										[(ngModel)]="current.zuchtauflagen_fr">
								</div>
							</div>
							<div class="col-12 mb-3">
								<label for="additional">Zusätzliche Tests (Deutsch)</label>
								<div class="input-group">
									<input #_additional="ngModel" type="text"
										class="form-control form-control-sm" id="additional"
										name="additional" maxlength="250"
										[(ngModel)]="current.additional">
								</div>
							</div>
							<div class="col-12 mb-3">
								<label for="additional_fr">Zusätzliche Tests
									(Französisch)</label>
								<div class="input-group">
									<input #_additional_fr="ngModel" type="text"
										class="form-control form-control-sm" id="additional_fr"
										name="additional_fr" maxlength="250"
										[(ngModel)]="current.additional_fr">
								</div>
							</div>
							<div class="col-12 mb-3">
								<label for="gelenke">Gelenke (Deutsch)</label>
								<div class="input-group">
									<input #_gelenke="ngModel" type="text"
										class="form-control form-control-sm" id="gelenke"
										name="gelenke" maxlength="250" [(ngModel)]="current.gelenke">
								</div>
							</div>
							<div class="col-12 mb-3">
								<label for="gelenke_fr">Gelenke (Französisch)</label>
								<div class="input-group">
									<input #_gelenke_fr="ngModel" type="text"
										class="form-control form-control-sm" id="gelenke_fr"
										name="gelenke_fr" maxlength="250"
										[(ngModel)]="current.gelenke_fr">
								</div>
							</div>
						</div>
					</div>
					<div class="col-5">
						<div class="form-row">
							<app-form-test-input class="col-12" label="CEA"
								[entities]="testList1" [(testModel)]="current.cea"
								[(dateModel)]="current.ceaDate"></app-form-test-input>
							<app-form-test-input class="col-12" label="PRA"
								[entities]="testList1" [(testModel)]="current.pra"
								[(dateModel)]="current.praDate"></app-form-test-input>
							<app-form-test-input class="col-12" label="KAT"
								[entities]="testList1" [(testModel)]="current.kat"
								[(dateModel)]="current.katDate"></app-form-test-input>
							<app-form-test-input class="col-12" label="ECVO"
								[entities]="testListEcvo" [(testModel)]="current.ecvo"
								[(dateModel)]="current.ecvoDate"></app-form-test-input>
							<app-form-test-input class="col-12" label="MDR1"
								[entities]="mdr1List" [(testModel)]="current.mdr1"
								[(dateModel)]="current.mdr1Date"></app-form-test-input>
							<app-form-test-input class="col-12" label="DM"
								[entities]="testList1" [(testModel)]="current.dm"
								[(dateModel)]="current.dmDate"></app-form-test-input>
							<app-form-test-input class="col-12" label="VWD3"
								[entities]="testList1" [(testModel)]="current.vwd3"
								[(dateModel)]="current.vwd3Date"></app-form-test-input>

							<app-form-test-input class="col-12" label="HD"
								[entities]="hdList" [(testModel)]="current.hd"
								[(dateModel)]="current.hd"></app-form-test-input>
							<app-form-test-input class="col-12" label="ED"
								[entities]="edList" [(testModel)]="current.ed"
								[(dateModel)]="current.edDate"></app-form-test-input>
							<app-form-test-input class="col-12" label="OCD"
								[entities]="ocdList" [(testModel)]="current.ocd"
								[(dateModel)]="current.ocdDate"></app-form-test-input>
							<app-form-test-input class="col-12" label="LSUGW"
								[entities]="lsugwList" [(testModel)]="current.lsugw"
								[(dateModel)]="current.lsugwDate"></app-form-test-input>
						</div>
					</div>
					<div class="col-5"></div>
				</div>
				<div *ngIf="error" class="alert alert-danger mt-3 mb-3">{{error.statusText}}</div>
				<div class="text-right">
					<div class="btn-group">
						<button type="button" class="btn btn-primary"
							[disabled]="_shsb.invalid" (click)="save()">Speichern</button>
						<button type="button" class="btn btn-link" (click)="cancel()">Abbrechen</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
<div class="col-12 py-md-3" *ngIf="!current">
	<div class="card">
		<div class="card-header container-fluid">
			<div class="row">
				<div class="col-md-6">Übersicht</div>
				<div class="col-md-6 text-right">
					<button type="button" class="btn btn-sm btn-primary ml-1"
						[disabled]="!mayEdit" (click)="add()">Hinzufügen</button>
					<button type="button" class="btn btn-sm btn-primary ml-1"
						(click)="exportExcel()">Excel Export</button>
				</div>
			</div>
		</div>
		<div class="row deckrueden">
			<div class="col-12">
				<table class="table table-sm" style="margin: auto">
					<ng-template ngFor let-item [ngForOf]="items">
					<tbody>
						<tr>
							<td colspan="2">
								<div class="row">
									<div class="col col-img">
										<div class="row no-gutters" style="height: 100%;">
											<div class="col col-12 col-img-sub">
												<img *ngIf="item.show_image"
													[src]="'/asset/dhl-' + item.shsb + '.jpg'"
													width="300" height="200">
											</div>
											<div class="col-12" style="margin-top: auto;">
												<button type="button" class="btn btn-link"
													[disabled]="current || !mayEdit" (click)="edit(item)">Editieren</button>
												<button type="button" class="btn btn-link"
													[disabled]="true" (click)="delete(item)">Löschen</button>
											</div>
										</div>
									</div>
									<div class="col">
										<div class="row">
											<div class="col-6">
												<table class="table table-striped">
													<tr>
														<th>Name</th>
														<td>{{item.name}} <span *ngIf="item.nickname">"{{item.nickname}}"</span></td>
													</tr>
													<tr>
														<th style="width: 20%">SHSB</th>
														<td>{{item.shsb}}</td>
													</tr>
													<tr>
														<th style="width: 20%">Wurfdatum</th>
														<td>{{item.birthdate | date}}</td>
													</tr>
													<tr *ngIf="item.title">
														<th style="width: 20%">Titel</th>
														<td>{{item.title}}</td>
													</tr>
													<tr>
														<th style="width: 20%">Farbe</th>
														<td>{{item.color}}</td>
													</tr>
													<tr>
														<th style="width: 20%">Vater<br>Mutter
														</th>
														<td>{{item.father}}<br>{{item.mother}}
														</td>
													</tr>
													<tr>
														<th style="width: 20%">Besitzer</th>
														<td>{{item.ownerName}}<br>
															{{item.town}}<br>
															{{item.phone}}<br *ngIf="item.phone">
															<div *ngIf="item.email">{{item.email}}</div>
															<div *ngIf="item.website"><a href="{{item.website}}"
															target="_blank" title="{{item.website}}">{{item.website}}</a></div>
														</td>
													</tr>
												</table>
											</div>
											<div class="col-6">
												<table class="table table-striped">
													<tr>
														<th style="width: 20%">Augen</th>
														<td>
															<div *ngIf="item.cea">CEA: {{item.cea}} <span *ngIf="item.ceaDate">({{item.ceaDate | date}})</span></div>
															<div *ngIf="item.pra">PRA: {{item.pra}} <span *ngIf="item.praDate">({{item.praDate | date}})</span></div>
															<div *ngIf="item.kat">KAT: {{item.kat}} <span *ngIf="item.katDate">({{item.katDate | date}})</span></div>
															<div *ngIf="item.ecvo">KAT: {{item.ecvo}} <span *ngIf="item.ecvoDate">({{item.ecvoDate | date}})</span></div>
														</td>
													</tr>
													<tr>
														<th style="width: 20%">Gentests</th>
														<td>
															<div *ngIf="item.mdr1">MDR 1: {{item.mdr1}} <span *ngIf="item.mdr1Date">({{item.mdr1Date | date}})</span></div>
															<div *ngIf="item.dm">DM: {{item.dm}} <span *ngIf="item.dmDate">({{item.dmDate | date}})</span></div>
															<div *ngIf="item.vwd3">vWD TypIII: {{item.vwd3}} <span *ngIf="item.vwd3Date">({{item.vwd3Date | date}})</span></div>
															<div *ngIf="item.additional">{{item.additional}} <span *ngIf="item.ceaDate">({{item.ceaDate | date}})</span></div>
														</td>
													</tr>
													<tr>
														<th style="width: 20%">Gelenke</th>
														<td>
															<div *ngIf="item.hd">HD: {{item.hd}} <span *ngIf="item.hdDate">({{item.hdDate | date}})</span></div>
															<div *ngIf="item.ed">ED: {{item.ed}} <span *ngIf="item.edDate">({{item.edDate | date}})</span></div>
															<div *ngIf="item.ocd">OCD: {{item.ocd}} <span *ngIf="item.ocdDate">({{item.ocdDate | date}})</span></div>
															<div *ngIf="item.lsugw">LS-ÜGW: {{item.lsugw}} <span *ngIf="item.lsugwDate">({{item.lsugwDate | date}})</span></div>
															<div *ngIf="item.gelenke">{{item.gelenke}}</div>
														</td>
													</tr>
													<tr>
														<th style="width: 20%">Zuchtzulassung</th>
														<td>
															<div *ngIf="item.formwert">Formwert: {{item.formwert}}</div>
															<div *ngIf="item.height">Widerristhöhe: {{item.height}} cm</div>
															<div *ngIf="item.gebiss">Gebiss: {{item.gebiss}}</div>
															<div *ngIf="item.zuchtauflagen">Zuchtauflage: {{item.zuchtauflagen}}</div>
															<div *ngIf="item.surveydate">Erteilt am: {{item.surveydate | date}}</div>
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
					</ng-template>
				</table>
			</div>
		</div>
	</div>
</div>
