<!-- Toolbar -->
<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
	<a class="navbar-brand" href="#">SSSC Admin</a>
	<ul class="navbar-nav mr-auto">
	</ul>

	<div class="navbar-brand">{{user}}</div>
	<a *ngIf="!user" class="btn navbar-brand" href="/login">Login</a>
	<button *ngIf="user" class="btn navbar-brand" (click)="logout()">Logout</button>
</nav>

	<div class="row no-gutters">

		<sssc-menu class="col-2" *ngIf="user"></sssc-menu>

		<div class="col">
			<router-outlet></router-outlet>
		</div>

	</div>
