import { Component, OnInit } from '@angular/core';
import { NewsService } from 'src/app/service/news.service';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

    news: any;

    current: any;

    error: any;

    constructor(private readonly newsService: NewsService) { }

    ngOnInit(): void {
        this.loadItems();
    }

    private loadItems() {
        this.newsService.getAll().then((results) => this.news = results);
        this.error = undefined;
        this.current = undefined;
    }

    public edit(newsItem) {
        this.current = newsItem;
    }

    private save() {
        this.newsService.save(this.current)
            .then((results) => this.loadItems())
            .catch((error) => this.error = error);
    }

    private add() {
        this.current = {};
        this.current.date = new Date();
    }

    public cancel() {
        this.loadItems();
    }
}
