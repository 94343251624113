<div class="col-md-6 offset-md-3 mt-5">
	<div class="card">
		<h4 class="card-header">Login</h4>
		<div class="card-body" *ngIf="!reset">
			<form name="login" (ngSubmit)="onSubmit()" class="needs-validation" [class.was-validated]="username.touched || password.touched" novalidate>
				<div class="form-group">
					<label for="username">Benutzername</label>
					<input name="username" class="form-control" required type="text" [(ngModel)]="email" #username="ngModel" />
					<div *ngIf="username.invalid && (username.dirty || username.touched)" class="invalid-feedback">
						Bitte Benutzername eingeben
					</div>
				</div>
				<div class="form-group">
					<label for="password">Passwort</label>
					<input name="password" type="password" class="form-control" required [(ngModel)]="passwd" #password="ngModel" />
					<div *ngIf="password.invalid" class="invalid-feedback">
						Bitte Passwort eingeben
					</div>
				</div>
				<div class="nav justify-content-end">
					<button [disabled]="loading || username.invalid || password.invalid" type="submit" class="btn btn-primary">
						<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
						Login
					</button>
					<button [disabled]="loading" type="button" class="btn btn-link" (click)="resetPassword()">
						Passwort zurücksetzen
					</button>
				</div>
				<div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error?.statusText}}</div>
			</form>
		</div>

		<div name="reset" class="card-body" *ngIf="reset">
			<form (ngSubmit)="onSubmit()" class="needs-validation" [class.was-validated]="username.dirty || username.touched" novalidate>
				<div class="form-group">
					<label for="username">Benutzername</label>
					<input name="username" class="form-control" required type="text" [(ngModel)]="email" #username="ngModel" />
					<div *ngIf="username.invalid && (username.dirty || username.touched)" class="invalid-feedback">
						Bitte Benutzername eingeben
					</div>
				</div>
				<div class="nav justify-content-end">
					<button [disabled]="loading || username.invalid || resetSuccess" type="submit" class="btn btn-primary">
						<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
						Passwort zurücksetzen
					</button>
				</div>
				<div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error.statusText}}</div>
				<div *ngIf="resetSuccess" class="alert alert-success mt-3 mb-0">E-Mail wurde gesendet</div>
			</form>
		</div>
	</div>
</div>