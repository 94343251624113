import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UserService } from './../service/user.service'

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    email: string;
    passwd: string;

    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';

    reset = false;
    resetSuccess = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService
    ) {
        // redirect to home if already logged in
        if (this.userService.user) {
            //this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    resetPassword() {
        this.reset = true;
        this.error = null;
    }

    onSubmit() {
        this.submitted = true;

        if (this.reset) {
            if (this.email) {
                this.loading = true;
                this.userService.resetRequest(this.email).then(
                    data => {
                        this.loading = false;
                        this.resetSuccess = true;
                    },
                    error => {
                        this.error = error;
                        this.loading = false;
                    });
            }
        } else {
            if (this.email && this.passwd) {
                this.loading = true;
                this.userService.login(this.email, this.passwd)
                    .then(
                        data => {
                            this.router.navigate([this.returnUrl]);
                        },
                        error => {
                            this.error = error;
                            this.loading = false;
                        });
            }
        }
    }
}
