<div class="form-row">
	<div class="col-md-6 mb-3">
		<label for="test">{{label}}</label>
		<div class="input-group">
			<select #_test="ngModel" type="text"
				class="form-control form-control-sm custom-select custom-select-sm" id="test" name="test"
				maxlength="250" [ngModel]="testModel" (ngModelChange)="testModelChange.emit($event)">
				<option *ngFor="let value of entities">{{value}}</option>
			</select>
		</div>
	</div>
	<div class="col-md-6 mb-3">
		<label for="testDate">{{label}} Test-Datum</label>
		<div class="input-group">
			<input class="form-control form-control-sm" type="text" id="testDate" name="testDate"
				[ngModel]="dateModel" (ngModelChange)="dateModelChange.emit($event)" container="body" ngbDatepicker
				#_testDate="ngModel" #dtestDate="ngbDatepicker" />
			<div class="input-group-append">
				<button class="btn btn-outline-secondary calendar"
					(click)="dtestDate.toggle()" type="button"></button>
			</div>
		</div>
		<div class="invalid-feedback">Ungültiges Datum</div>
	</div>
</div>