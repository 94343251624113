<div class="col-12 py-md-3" *ngIf="current">
    <div class="card">
        <div class="card-header">
            Event erfassen / bearbeiten
        </div>
        <div class="card-body">
            <form class="needs-validation" [class.was-validated]="eventDate.touched || nameDe.touched || nameFr.touched || url.touched"
                novalidate>
                <div class="form-row">
                    <div class="col-12 mb-3">
                        <label for="date">Datum</label>
                        <div class="input-group">
                            <input class="form-control" type="text" id="date" name="date" required
                                [(ngModel)]="current.date" container="body" ngbDatepicker #eventDate="ngModel" #d1="ngbDatepicker" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()"
                                    type="button"></button>
                            </div>
                            <div class="invalid-feedback">
                                Ungültiges Datum
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <label for="nameDe">Event Name Deutsch</label>
                        <div class="input-group">
                            <input #nameDe="ngModel" type="text" class="form-control" id="nameDe" name="name_de"
                                required maxlength="250" [(ngModel)]="current.name_de">
                            <div class="invalid-feedback">
                                Bitte Name des Events angeben.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="nameFr">Event Name Französisch</label>
                        <div class="input-group">
                            <input #nameFr="ngModel" type="text" class="form-control" id="nameFr" name="name_fr"
                                maxlength="250" [(ngModel)]="current.name_fr"
                                [placeholder]="current.name_de ? current.name_de : ''">
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <label for="locationDe">Ort Deutsch</label>
                        <div class="input-group">
                            <input #locationDe="ngModel" type="text" class="form-control" id="locationDe"
                                name="location_de" required maxlength="250" [(ngModel)]="current.location_de">
                            <div class="invalid-feedback">
                                Bitte Ort angeben.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="locationFr">Ort Französisch</label>
                        <div class="input-group">
                            <input #locationFr="ngModel" type="text" class="form-control" id="locationFr"
                                name="location_fr" maxlength="250" [(ngModel)]="current.location_fr"
                                [placeholder]="current.location_de ? current.location_de : ''">
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <label for="commentDe">Kommentar Deutsch</label>
                        <div class="input-group">
                            <input #commentDe="ngModel" type="text" class="form-control" id="commentDe"
                                name="comment_de" maxlength="250" [(ngModel)]="current.comment_de">
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="commentFr">Kommentar Französisch</label>
                        <div class="input-group">
                            <input #commentFr="ngModel" type="text" class="form-control" id="commentFr"
                                name="comment_fr" maxlength="250" [(ngModel)]="current.comment_fr"
                                [placeholder]="current.comment_de ? current.comment_de : ''">
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <label for="url">URL</label>
                        <div class="input-group">
                            <input #url="ngModel" type="text" class="form-control" id="url" name="url"
                                [(ngModel)]="current.map_url" pattern="https?://.+" placeholder="https://">
                        </div>
                    </div>
                </div>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-3">{{error.statusText}}</div>
                <div class="text-right">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary"
                            [disabled]="eventDate.invalid || nameDe.invalid || nameFr.invalid || locationDe.invalid || locationFr.invalid || url.invalid"
                            (click)="save()">Speichern</button>
                        <button type="button" class="btn btn-link" (click)="cancel()">Abbrechen</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="col-12 py-md-3" *ngIf="!current">
    <div class="card">
        <div class="card-header">
            Übersicht
        </div>
        <div class="card-body">
            <table class="table table-sm table-striped table-hover">
                <thead>
                    <th style="width: 8rem">Datum</th>
                    <th>Text</th>
                    <th>Ort</th>
                    <th style="width: 14rem">Aktionen</th>
                </thead>
                <tbody>
                    <tr class="table-row" *ngFor="let eventItem of events">
                        <td>{{eventItem.date | date}}</td>
                        <td [innerHTML]="eventItem.name_de"></td>
                        <td [innerHTML]="eventItem.location_de"></td>
                        <td>
                            <div class="btn-group">
                                <button type="button" class="btn btn-link" [disabled]="current"
                                    (click)="edit(eventItem)">Editieren</button>
                                <button type="button" class="btn btn-link" [disabled]="current">Löschen</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="text-right">
                <div class="btn-group">
                    <button type="button" class="btn btn-primary" (click)="add()">Hinzufügen</button>
                </div>
            </div>
        </div>
    </div>