<div class="col-md-6 offset-md-3 mt-5">
    <div class="card">
        <h4 class="card-header">Login</h4>
        <div class="card-body">
            <form name="login" (ngSubmit)="onSubmit()" class="needs-validation"
                [class.was-validated]="username.touched || tokenId.touched || password.touched || password2.touched"
                novalidate>
                <div class="form-group">
                    <label for="username">Benutzername</label>
                    <input name="username" class="form-control" required type="text" [(ngModel)]="email"
                        #username="ngModel" />
                    <div *ngIf="username.invalid && (username.dirty || username.touched)" class="invalid-feedback">
                        Bitte Benutzername eingeben
                    </div>
                </div>
                <div class="form-group">
                    <label for="token">Reset Token</label>
                    <input name="token" class="form-control" required type="text" [(ngModel)]="token"
                        #tokenId="ngModel" />
                    <div *ngIf="tokenId.invalid && (tokenId.dirty || tokenId.touched)" class="invalid-feedback">
                        Bitte Reset Token eingeben
                    </div>
                </div>
                <div class="form-group">
                    <label for="password">Passwort</label>
                    <input name="password" type="password" class="form-control" required appPwStrength="4"
                        [(ngModel)]="passwd" #password="ngModel" (onchange)="password2"/>
                    <div *ngIf="password.errors?.required" class="invalid-feedback">
                        Bitte Passwort eingeben
                    </div>
                    <div *ngIf="password.errors?.minstrength && !password.errors?.required" class="invalid-feedback">
                        Bitte ein starkes Passwort wählen (sehr gut oder vorzüglich)
                    </div>
                    <app-password-strength-bar [passwordToCheck]="passwd" [barLabel]="barLabel">
                    </app-password-strength-bar>
                </div>
                <div class="form-group">
                    <label for="password2">Passwort wiederholen</label>
                    <input name="password2" type="password" class="form-control" required appPwMatch [(ngModel)]="passwd2"
                        #password2="ngModel" />
                    <div *ngIf="password2.errors?.required" class="invalid-feedback">
                        Bitte Passwort wiederholen
                    </div>
                    <div *ngIf="password2.errors?.match && !password2.errors?.required" class="invalid-feedback">
                        Passwort stimmt nicht überein
                    </div>
                </div>
                <div class="nav justify-content-end">
                    <button
                        [disabled]="loading || username.invalid || tokenId.invalid || password.invalid || password2.invalid"
                        type="submit" class="btn btn-primary">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Neues Passwort setzen
                    </button>
                </div>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error?.statusText}}</div>
            </form>
        </div>
    </div>
</div>