import { Component } from '@angular/core';
import { UserService } from './service/user.service';
import { Router } from '@angular/router';
import { LOCALE_ID, Inject } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(public readonly userService: UserService,
                private readonly router: Router) {
    }

    get user(): string {
        return this.userService?.user?.name;
    }

    login(): void {
        this.router.navigate(['login']);
    }

    logout(): void {
        this.userService.logout().then(() => this.router.navigate(['login']));
    }
}
