import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    public user: any;

    private readonly options = {
        withCredentials: true
    };

    constructor(private http: HttpClient) {
        this.loadUser();
    }

    public loadUser() {
        this.http.get<string>('api/login', this.options).toPromise().then((user) => this.user = user);
    }

    public login(username: string, password: string): Promise<string> {
        const request = this.http.post<string>('api/login', { 'email': username, 'pass': password }, this.options).toPromise();
        request.then((result) => this.loadUser());
        return request;
    }

    public logout(): Promise<string> {
        const request = this.http.post<string>('api/logout', null, this.options);
        request.subscribe((result) => this.loadUser());
        return request.toPromise();
    }

    public resetRequest(username: string): Promise<string> {
        const request = this.http.post<string>('api/login/reset', { 'email': username }, this.options);
        return request.toPromise();
    }

    public reset(username: string, token: string, password: string): Promise<string> {
        const request = this.http.post<string>('api/login/reset', { 'email': username, 'token': token, 'password': password }, this.options);
        return request.toPromise();
    }

}
