import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
    selector: '[appPwMatch]',
    providers: [{ provide: NG_VALIDATORS, useExisting: PwMatchDirective, multi: true }]

})
export class PwMatchDirective implements Validator {

    constructor() { }

    validate(control: AbstractControl): { [key: string]: any } | null {
        const password = control.parent.get('password');
        const password2 = control.parent.get('password2');
        if (password?.value === password2?.value) {
            return null;
        }
        return { 'match': 'miss-match' };
    }

}
