import { Component, OnInit } from '@angular/core';
import { DeckruedenService } from 'src/app/service/deckrueden.service';

@Component({
    selector: 'app-deckrueden',
    templateUrl: './deckrueden.component.html',
    styleUrls: ['./deckrueden.component.scss']
})
export class DeckruedenComponent implements OnInit {

    items: any;

    current: any;

    error: any;

    titles: string[] = [null];

    testList1 = ['gen. frei', 'gen. carrier', 'frei', 'frei, gen. carrier'];

    colorList = ['bi-blue', 'bluemerle', 'bluemerle, bi-fakt.', 'sable', 'schwarz-weiss', 'tricolor'];

    formwertList = ['vorzüglich', 'sehr gut'];

    mdr1List: string[] = [];
    hdList: string[] = [];

    constructor(private readonly deckruedenService: DeckruedenService) { }

    ngOnInit(): void {
        this.loadItems();
    }

    private loadItems() {
        this.deckruedenService.getAll().then((results) => this.loadResults(results));
        this.current = undefined;
        this.error = undefined;
    }

    private loadResults(results) {
        this.items = results;

        this.titles = this.collectUnique(results, [null], 'title');
        this.testList1 = this.collectUnique(results, this.testList1, 'cea');
        this.testList1 = this.collectUnique(results, this.testList1, 'pra');
        this.testList1 = this.collectUnique(results, this.testList1, 'kat');
        this.testList1 = this.collectUnique(results, this.testList1, 'dm');
        this.testList1 = this.collectUnique(results, this.testList1, 'vwd3');

        this.mdr1List = this.collectUnique(results, [], 'mdr1');
        this.hdList = this.collectUnique(results, [], 'hd');

        this.colorList = this.collectUnique(results, this.colorList, 'color');
        this.formwertList = this.collectUnique(results, this.formwertList, 'formwert');
    }

    private collectUnique(results: any, list: string[], value: string) {
        results.forEach(element => {
            if (element[value] && list.indexOf(element[value]) < 0) {
                list.push(element[value]);
            }
        });
        return list;
    }

    public edit(newsItem) {
        this.current = newsItem;
        if (this.current['birthdate']) {
            this.current['birthdate'] = new Date(this.current['birthdate']);
        }
        if (this.current['surveydate']) {
            this.current['surveydate'] = new Date(this.current['surveydate']);
        }

        if (this.current['ceaDate']) {
            this.current['ceaDate'] = new Date(this.current['ceaDate']);
        }
        if (this.current['praDate']) {
            this.current['praDate'] = new Date(this.current['praDate']);
        }
        if (this.current['katDate']) {
            this.current['katDate'] = new Date(this.current['katDate']);
        }
        if (this.current['dmDate']) {
            this.current['dmDate'] = new Date(this.current['dmDate']);
        }
        if (this.current['vwd3Date']) {
            this.current['vwd3Date'] = new Date(this.current['vwd3Date']);
        }
        if (this.current['mdr1Date']) {
            this.current['mdr1Date'] = new Date(this.current['mdr1Date']);
        }
    }

    private save() {
        this.deckruedenService.save(this.current)
            .then((results) => this.loadItems())
            .catch((error) => this.error = error);
    }

    private add() {
        this.current = {};
        this.current.date = new Date();
    }

    public cancel() {
        this.loadItems();
    }


}
