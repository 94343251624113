import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PwStrengthService {

    public measureStrength(pass: string) {
        let score = 0;
        // award every unique letter until 5 repetitions  
        let letters = {};
        for (let i = 0; i < pass.length; i++) {
            letters[pass[i]] = (letters[pass[i]] || 0) + 1;
            score += 5.0 / letters[pass[i]];
        }
        // bonus points for mixing it up  
        let variations = {
            digits: /\d/.test(pass),
            lower: /[a-z]/.test(pass),
            upper: /[A-Z]/.test(pass),
            nonWords: /\W/.test(pass),
        };
        let variationCount = 0;
        for (let check in variations) {
            variationCount += (variations[check]) ? 1 : 0;
        }
        score += (variationCount - 1) * 10;
        score = Math.trunc(score);
        return { score: score, rank: this.getRank(score) };
    }

    private getRank(score: number): number {
        let rank = 0;
        if (score > 90) {
            rank = 4;
        } else if (score > 70) {
            rank = 3;
        } else if (score >= 40) {
            rank = 2;
        } else if (score >= 20) {
            rank = 1;
        }
        return rank;
    }
}
