import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-form-test-input',
	templateUrl: './form-test-input.component.html',
	styleUrls: ['./form-test-input.component.scss']
})
export class FormTestInputComponent {

	@Input() label: string;
	@Input() entities: string[];

	@Input() testModel: string;
	@Output() testModelChange = new EventEmitter<any>();

	@Input() dateModel: any;
	@Output() dateModelChange = new EventEmitter<any>();

	constructor() {
	}

}
