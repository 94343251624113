import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class DeckruedenService {
    private readonly options = {
        withCredentials: true
    };

    constructor(private http: HttpClient) { }

    public getAll(): Promise<any> {
        return this.http.get<string>('api/zucht/ruede', this.options).toPromise();
    }

    public save(deckruede: any): Promise<any> {
        return this.http.put<string>('api/zucht/ruede', deckruede, this.options).toPromise();
    }

}
