import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class NewsService {
    private readonly options = {
        withCredentials: true
    };

    constructor(private http: HttpClient) { }

    public getAll(): Promise<any> {
        return this.http.get<string>('api/news', this.options).toPromise();
    }

    public save(news: any): Promise<any> {
        return this.http.put<string>('api/news', news, this.options).toPromise();
    }

}
