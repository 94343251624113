import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ZuchthuendinnenService {
    private readonly options = {
        withCredentials: true
    };

    constructor(private http: HttpClient) { }

    public getAll(): Promise<any> {
        return this.http.get<string>('api/zucht/huendin', this.options).toPromise();
    }

    public save(deckruede: any): Promise<any> {
        return this.http.put<string>('api/zucht/huendin', deckruede, this.options).toPromise();
    }

    public delete(huendin: any): Promise<any> {
        return this.http.delete<string>('api/zucht/huendin/' + huendin.shsb).toPromise();
    }

}
