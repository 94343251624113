import { Component, OnInit } from '@angular/core';
import { ZuechterService } from 'src/app/service/zuechter.service';
import { UserService } from './../../service/user.service';

@Component({
	selector: 'app-zuechter',
	templateUrl: './zuechter.component.html',
	styleUrls: ['./zuechter.component.scss']
})
export class ZuechterComponent implements OnInit {

	zuechterList: any;

	current: any;

	error: any;

	constructor(private readonly zuechterService: ZuechterService,
		public readonly userService: UserService) {
	}

	ngOnInit(): void {
		this.loadItems();
	}

	get mayEdit(): boolean {
		console.log(this.userService.user?.rights);
		return this.userService.user?.rights?.indexOf('ZUCHT_WRITE') >= 0;
	}

	private loadItems() {
		this.zuechterService.getAll().then((results) => this.zuechterList = results);
		this.error = undefined;
		this.current = undefined;
	}

	public edit(item) {
		this.current = item;
	}

	private save() {
		this.zuechterService.save(this.current)
			.then((results) => this.loadItems())
			.catch((error) => this.error = error);
	}

	private delete(item) {
		if (confirm("Züchter " + item.displayname + " wirklich löschen?")) {
			this.zuechterService.delete(item)
				.then((results) => this.loadItems())
				.catch((error) => this.error = error);
		}
	}

	private add() {
		this.current = {};
		this.current.date = new Date();
	}

	public cancel() {
		this.loadItems();
	}
}
