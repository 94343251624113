import { Component, OnChanges, Input, SimpleChange } from '@angular/core';
import { PwStrengthService } from '../../service/pw-strength.service';

@Component({
    selector: 'app-password-strength-bar',
    templateUrl: './password-strength-bar.component.html',
    styleUrls: ['./password-strength-bar.component.scss']
})
export class PasswordStrengthBarComponent {

    @Input() passwordToCheck: string;
    @Input() barLabel: string;
    bar0: string;
    bar1: string;
    bar2: string;
    bar3: string;
    bar4: string;

    label: string;

    private colors = ['#F00', '#F90', '#FF0', '#9F0', '#0F0'];

    constructor(private readonly pwStrengthService: PwStrengthService) {
    }

    private getColor(rank: number) {
        console.log(rank);
        return {
            idx: rank + 1,
            col: this.colors[rank]
        };
    }

    ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
        const text = ['disqualifiziert',
            'genügend',
            'gut',
            'seht gut',
            'vorzüglich'];

        var password = changes['passwordToCheck'].currentValue;
        this.setBarColors(5, '#DDD');
        if (password) {
            const strength = this.pwStrengthService.measureStrength(password);
            const c = this.getColor(strength.rank);
            this.setBarColors(c.idx, c.col);
            this.label = text[strength.rank];
        }
    }
    private setBarColors(count, col) {
        for (let _n = 0; _n < count; _n++) {
            this['bar' + _n] = col;
        }
    }
}