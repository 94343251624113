import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';

@Injectable()
export class NgbDateI18NParserFormatter extends NgbDateParserFormatter {

    constructor(@Inject(LOCALE_ID) private locale: string) {
        super();
        moment.locale(this.locale);
    }

    parse(value: string): NgbDateStruct {
        if (value == null || !value) { return null; }
        const date = moment(value, 'L');
        console.log(value);
        return { year: date.year(), month: date.month() + 1, day: date.date() };
    }

    format(date: NgbDateStruct): string {
        if (date == null || !date) { return null; }
        return moment().year(date.year).month(date.month - 1).date(date.day).format('L');
    }
}
