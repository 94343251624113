<div class="col-12 py-md-3" *ngIf="current">
    <div class="card">
        <div class="card-header">
            News erfassen / bearbeiten
        </div>
        <div class="card-body">
            <form class="needs-validation" [class.was-validated]="titleDe.touched || titleFr.touched || url.touched"
                novalidate>
                <div class="form-row">
                    <div class="col-12 mb-3">
                        <label for="validationCustomUsername">Datum</label>
                        <div class="input-group">
                            <input type="text" class="form-control" id="validationCustomUsername"
                                [value]="current?.date | date: 'dd.MM.yyyy'"
                                readonly>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <label for="titleDe">Titel Deutsch</label>
                        <div class="input-group">
                            <textarea #titleDe="ngModel" type="text" class="form-control" id="titleDe" name="title_de" required
                                rows="3" maxlength="250" [(ngModel)]="current.title_de"></textarea>
                            <div class="invalid-feedback">
                                Please choose a username.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="titleFr">Titel Französisch</label>
                        <div class="input-group">
                            <textarea #titleFr="ngModel" type="text" class="form-control" id="titleFr" name="title_fr" rows="3"
                                maxlength="250" [(ngModel)]="current.title_fr"
                                [placeholder]="current.title_de ? current.title_de : ''"></textarea>
                            <div class="invalid-feedback">
                                Please choose a username.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <label for="url">URL</label>
                        <div class="input-group">
                            <input #url="ngModel" type="text" class="form-control" id="url" name="url"
                                [(ngModel)]="current.url" pattern="https?://.+" placeholder="https://">
                        </div>
                    </div>
                </div>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-3">{{error.statusText}}</div>
                <div class="text-right">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary"
                            [disabled]="titleDe.invalid || titleFr.invalid || url.invalid"
                            (click)="save()">Speichern</button>
                        <button type="button" class="btn btn-link" (click)="cancel()">Abbrechen</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="col-12 py-md-3" *ngIf="!current">
    <div class="card">
        <div class="card-header">
            Übersicht
        </div>
        <div class="card-body">
            <table class="table table-sm table-striped table-hover">
                <thead>
                    <th style="width: 8rem">Datum</th>
                    <th>Text</th>
                    <th style="width: 14rem">Aktionen</th>
                </thead>
                <tbody>
                    <tr class="table-row" *ngFor="let newsItem of news">
                        <td>{{newsItem.date | date: 'dd.MM.yyyy'}}</td>
                        <td [innerHTML]="newsItem.title_de"></td>
                        <td>
                            <div class="btn-group">
                                <button type="button" class="btn btn-link" [disabled]="current"
                                    (click)="edit(newsItem)">Editieren</button>
                                <button type="button" class="btn btn-link" [disabled]="current">Löschen</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="text-right">
                <div class="btn-group">
                    <button type="button" class="btn btn-primary" (click)="add()">Hinzufügen</button>
                </div>
            </div>
        </div>
    </div>